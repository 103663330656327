import { FC, useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui';
import { chains } from '@/lib/wagmi/config';
import { useAccount, useSwitchChain } from 'wagmi';
import { useUnifiedAccount } from '@/hooks';

interface NetworkModalProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  displayTrigger?: boolean;
}

export const NetworkModal: FC<NetworkModalProps> = ({
  isOpen: externalIsOpen,
  onOpenChange = () => {},
  displayTrigger = true,
}) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const { networkType, chain } = useUnifiedAccount();
  const { switchChain, chains } = useSwitchChain();
  const { chain: activeChain } = useAccount();
  const isControlled = externalIsOpen !== undefined;
  const isOpen = isControlled ? externalIsOpen : internalIsOpen;

  useEffect(() => {
    if (isControlled) {
      setInternalIsOpen(externalIsOpen);
    }
  }, [externalIsOpen, isControlled]);

  const handleOpenChange = (newIsOpen: boolean) => {
    if (!isControlled) {
      setInternalIsOpen(newIsOpen);
    }
    onOpenChange?.(newIsOpen);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="gap-0 border border-border bg-background p-0 sm:max-w-sm sm:rounded-none">
        <DialogHeader className="px-5 pt-6">
          <DialogTitle className="text-xl text-foreground">
            Switch Network
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center gap-3 px-4 pb-6 pt-4">
          {networkType === 1 && (
            <Button
              key={chain?.name}
              variant="outline"
              className={`h-auto w-full border-primary py-2 font-mono text-lg font-normal hover:bg-vestgrey-800`}
            >
              {chain?.name}
            </Button>
          )}
          {!networkType &&
            chains.map((chain) => (
              <Button
                key={chain.id}
                variant="outline"
                className={`h-auto w-full py-2 font-mono text-lg font-normal ${activeChain === chain ? 'border-primary' : ''} hover:bg-vestgrey-800`}
                onClick={() => {
                  switchChain({ chainId: chain.id });
                  handleOpenChange(false);
                }}
              >
                {chain.name}
              </Button>
            ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
