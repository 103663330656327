import { publicApi } from '@/lib/api-clients/rest-client';
import { useAccountStore } from '@/store/use-account-store';
import { useQuery } from '@tanstack/react-query';

export type LpPerformanceRecord = {
  shares: string;
  pricePerShare: string;
  ts: string;
};

export const PERIODS = [
  // {
  //   label: '1D',
  //   offset: 86400000,
  //   limit: 1440,
  // },
  // {
  //   label: '1W',
  //   offset: 604800000,
  //   limit: 7 * 24 * 60,
  // },
  {
    label: '1M',
    offset: 31 * 24 * 60 * 60 * 1000,
    limit: 31,
  },
] as const;

async function getPerformance(
  offset: number,
  limit?: number,
): Promise<LpPerformanceRecord[]> {
  const now = useAccountStore.getState().getAccurateTime();
  const timeTo = new Date(now);
  const timeFrom = new Date(now);
  timeFrom.setTime(timeFrom.getTime() - offset);
  return publicApi
    .get('/lpPerformance', {
      params: {
        timeFrom,
        timeTo,
        limit,
      },
    })
    .then((res) => res.data);
}

export default function useLpPerformance({
  offset,
  limit,
}: Omit<(typeof PERIODS)[number], 'label'>) {
  return useQuery({
    queryKey: ['lp', 'performance', { offset, limit }],
    queryFn: () => getPerformance(offset, limit),
  });
}
