import {
  Input,
  Table,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui';
import useOpenInterest from '@/features/markets/api/get-open-interest';
import { useTicker24hr } from '@/features/markets/api/get-ticker-24hr';
import { useTickerLatest } from '@/features/markets/api/get-ticker-latest';
import { FavoriteButton } from '@/features/markets/components/favorite-button';
import { getMaxLeverage } from '@/features/trade/order/utils/math';
import { useFavorites } from '@/hooks';
import { cn } from '@/lib/utils';
import {
  FUNDING_RATE_DECIMALS,
  useMarketStores,
} from '@/store/use-markets-store';
import {
  abbrFromSym,
  imgFromAbbr,
  symbolToBaseQuote,
} from '@/utils/token-symbol';
import {
  bigIntToDecimalStr,
  formatBigInt,
  formatNumber,
} from '@/utils/value-format';
import { ColumnDef } from '@tanstack/react-table';
import { Search } from 'lucide-react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

enum TableTabType {
  ALL = 'all',
  FAVORITE = 'favorite',
  NEW = 'new',
}

interface MarketItem {
  symbol: string;
  leverage: string;
  markPrice: bigint;
  indexPrice: bigint;
  priceChange: bigint;
  priceChangePct: bigint;
  fundingRate: bigint;
  isFavorite: boolean;
  quoteVolume?: bigint;
  openInterest?: bigint;
}

const NEW_SYMBOLS = ['TRUMP-PERP', 'MELANIA-PERP', 'AIXBT-PERP', 'ANIME-PERP', 'BERA-PERP', 'TST-PERP'];

export default function MarketsPage() {
  const navigate = useNavigate();
  const [tableTab, setTableTab] = useState(TableTabType.ALL);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const { isFavorite } = useFavorites();

  const markets = useMarketStores((state) => ({
    marketData: state.marketData,
    marketSpec: state.marketSpec,
  }));

  useTickerLatest({});
  useTicker24hr({});
  useOpenInterest({});

  const columns: ColumnDef<MarketItem>[] = useMemo(
    () => [
      {
        accessorKey: 'symbol',
        header: 'Symbol',
        cell: ({ row }) => (
          <div className="flex h-full items-center gap-3">
            <div className="mr-1.5">
              <FavoriteButton symbol={row.original.symbol} size={14} />
            </div>
            <img
              src={imgFromAbbr(abbrFromSym(row.original.symbol))}
              alt="symbol"
              className="h-5 w-5"
            />
            <span className="font-sans">
              {symbolToBaseQuote(row.original.symbol)}
            </span>
            <div className="rounded bg-[#271714] px-2">
              <span className="font-mono text-sm text-primary">
                {formatNumber(row.original.leverage, {
                  style: 'leverage',
                })}
              </span>
            </div>
          </div>
        ),
      },
      {
        accessorKey: 'markPrice',
        header: 'Mark Price',
        cell: ({ row }) => {
          const { priceDecimals } = markets[row.original.symbol].marketSpec;
          return (
            <div className={`flex items-center`}>
              <span>{formatBigInt(row.original.markPrice, priceDecimals)}</span>
            </div>
          );
        },
      },
      {
        accessorKey: 'indexPrice',
        header: 'Index Price',
        cell: ({ row }) => {
          const { priceDecimals } = markets[row.original.symbol].marketSpec;
          return (
            <div className={`flex items-center`}>
              <span>
                {formatBigInt(row.original.indexPrice, priceDecimals)}
              </span>
            </div>
          );
        },
      },
      {
        accessorKey: 'priceChange',
        header: '24h Change',
        cell: ({ row }) => {
          const { priceDecimals } = markets[row.original.symbol].marketSpec;
          return (
            <div className={`flex items-center`}>
              <span
                className={cn({
                  'text-red': row.original.priceChange < 0,
                  'text-green': row.original.priceChange >= 0,
                })}
              >
                {`${formatBigInt(row.original.priceChange, priceDecimals, { showChange: true })} / ${formatBigInt(row.original.priceChangePct, 4n, { style: 'percent', abs: true })}`}
              </span>
            </div>
          );
        },
        sortingFn: (a, b) =>
          Number(a.original.priceChangePct) - Number(b.original.priceChangePct),
      },
      {
        accessorKey: 'fundingRate',
        header: '1h Funding',
        cell: ({ row }) => (
          <div className={`flex items-center`}>
            <span
              className={cn({
                'text-red': row.original.fundingRate < 0,
                'text-green': row.original.fundingRate >= 0,
              })}
            >
              {formatBigInt(row.original.fundingRate, FUNDING_RATE_DECIMALS, {
                style: 'percent',
                digits: Number(FUNDING_RATE_DECIMALS) - 2,
              })}
            </span>
          </div>
        ),
      },
      // {
      //   id: 'oi',
      //   header: 'Open Interest',
      //   cell: ({ row }) => {
      //     const { priceDecimals, sizeDecimals } =
      //       markets[row.original.symbol].marketSpec;
      //     return typeof row.original.openInterest === 'bigint'
      //       ? formatBigInt(
      //           row.original.openInterest,
      //           priceDecimals + sizeDecimals,
      //           { digits: 2 },
      //         )
      //       : '-';
      //   },
      // },
      // {
      //   accessorKey: 'volume',
      //   header: '24h Volume',
      //   cell: ({ row }) => {
      //     const { priceDecimals, sizeDecimals } =
      //       markets[row.original.symbol].marketSpec;
      //     return typeof row.original.quoteVolume === 'bigint'
      //       ? formatBigInt(
      //           row.original.quoteVolume,
      //           priceDecimals + sizeDecimals,
      //           {
      //             digits: 2,
      //           },
      //         )
      //       : '-';
      //   },
      // },
    ],
    [],
  );

  const data = useMemo(() => {
    return Object.entries(markets)
      .filter(
        ([symbol]) =>
          abbrFromSym(symbol)
            .toLowerCase()
            .includes(debouncedSearch.toLowerCase()) &&
          (tableTab === TableTabType.ALL ||
            (tableTab === TableTabType.FAVORITE && isFavorite(symbol)) ||
            (tableTab === TableTabType.NEW && NEW_SYMBOLS.includes(symbol))),
      )
      .map(([symbol, market]) => ({
        symbol,
        leverage: getMaxLeverage(market.marketSpec)?.decimal,
        markPrice: market.marketData.markPrice,
        indexPrice: market.marketData.indexPrice,
        priceChange: market.marketData.priceChange,
        priceChangePct: market.marketData.priceChangePct,
        fundingRate: market.marketData.oneHrFundingRate * 100n,
        isFavorite: tableTab === TableTabType.FAVORITE || isFavorite(symbol),
        quoteVolume: market.marketData.quoteVolume,
        openInterest: market.marketData.openInterest,
      }))
      .sort((a, b) => {
        if (a.isFavorite && !b.isFavorite) return -1;
        if (!a.isFavorite && b.isFavorite) return 1;
        return a.symbol.localeCompare(b.symbol);
      }) as MarketItem[];
  }, [debouncedSearch, markets, tableTab]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 100);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  // const favorite = data.filter((symbol) => symbol.isFavorite);

  return (
    <Tabs
      className="flex h-full flex-col"
      value={tableTab}
      onValueChange={(value) => setTableTab(value as TableTabType)}
    >
      <TabsList className="grid h-16 grid-cols-5 items-stretch border-b border-border">
        <div className="col-span-4">
          <TabsTrigger
            className="h-full border-t-0 border-b-transparent"
            value="all"
          >
            All markets
          </TabsTrigger>
          <TabsTrigger
            className="h-full border-t-0 border-b-transparent"
            value="favorite"
          >
            Favorites
          </TabsTrigger>
          <TabsTrigger
            className="h-full border-t-0 border-b-transparent"
            value="new"
          >
            New
          </TabsTrigger>
        </div>
        <div className="relative flex h-full items-center border-l border-border">
          <Search className="absolute left-6 text-foreground" size={16} />
          <input
            type="text"
            placeholder="Search"
            className="relative z-10 w-full rounded-none bg-transparent pl-16 font-mono text-foreground placeholder:uppercase focus:outline-none"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </TabsList>
      <TabsContent className="relative flex-1" value="all">
        <div className="absolute inset-0 h-full w-full [&_tr]:cursor-pointer">
          <Table<MarketItem>
            columns={columns}
            data={data}
            onRowClick={(row) => navigate(`/trade/${row.symbol}`)}
          />
        </div>
      </TabsContent>
      <TabsContent className="relative flex-1" value="favorite">
        {data.length > 0 ? (
          <div className="absolute inset-0 h-full w-full [&_tr]:cursor-pointer">
            <Table<MarketItem>
              columns={columns}
              data={data}
              onRowClick={(row) => navigate(`/trade/${row.symbol}`)}
            />
          </div>
        ) : (
          <div className="flex h-full flex-col items-center justify-center gap-4">
            {/* <CircleX size={20} className="text-vestgrey-100" /> */}
            <p className="font-mono text-lg uppercase text-vestgrey-100">
              No favorites yet, go add some
            </p>
          </div>
        )}
      </TabsContent>
      <TabsContent className="relative flex-1" value="new">
        <div className="absolute inset-0 h-full w-full [&_tr]:cursor-pointer">
          <Table<MarketItem>
            columns={columns}
            data={data}
            onRowClick={(row) => navigate(`/trade/${row.symbol}`)}
          />
        </div>
      </TabsContent>
    </Tabs>
  );
}
