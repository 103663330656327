import { Button } from '@/components/ui';
import { useUnifiedAccount } from '@/hooks';

import { FC } from 'react';

interface IProps {
  icon: string;
  label: string;
  isMore?: boolean;
  onClick: () => void;
}

export const ButtonWallet: FC<IProps> = ({ icon, label, isMore, onClick }) => {
  const { connector } = useUnifiedAccount();

  if (isMore) {
    return (
      <Button onClick={onClick} variant="outline">
        <div className="btn-wallet--more">{label}</div>
      </Button>
    );
  }

  const isActiveWallet = connector && connector.name === label;

  return (
    <Button
      onClick={onClick}
      variant="outline"
      className={`relative h-auto px-3 py-6 hover:bg-vestgrey-800 ${isActiveWallet ? 'pointer-events-none border-primary bg-vestgrey-800' : ''}`}
    >
      <div className="btn-wallet flex w-full flex-col items-center justify-start gap-3 font-mono text-lg font-normal">
        <img src={icon} className="h-12 w-12 rounded-md" alt="icon" />
        {label.toUpperCase()}
      </div>
      {isActiveWallet && (
        <div className="absolute bottom-1.5 text-primary">connected</div>
      )}
    </Button>
  );
};
