import { ErrorCode, ErrorMsgs, LPOrderType, OrderStatus } from '@/types';
import { LpOrder, useLpOrders } from '../api/get-lp-orders';
import { Table } from '@/components/ui';
import { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import {
  formatDate,
  formatLpOrderType,
  formatNumber,
  formatOrderStatus,
} from '@/utils/value-format';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { Info } from 'lucide-react';

const columns: ColumnDef<LpOrder>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    cell: ({ row }) => (
      <div className="flex items-center gap-4">
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger className="text-vestgrey-100">
              <Info size={14} />
            </TooltipTrigger>
            <TooltipContent className="break-all">
              {row.original.id}
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    ),
    meta: { width: 'w-[2.5%]' },
  },
  {
    accessorKey: 'postTime',
    header: 'TIME',
    cell: ({ row }) =>
      formatDate(new Date(row.original.postTime).getTime(), {
        showTime: true,
        showSeconds: true,
      }),
    meta: { width: 'w-[10%]' },
  },
  {
    accessorKey: 'size',
    header: 'AMOUNT',
    cell: ({ row }) => formatNumber(row.original.size, { digits: 2 }),
    meta: { width: 'w-[12.5%]' },
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    cell: ({ row }) => (
      <div className="flex items-center gap-4">
        {row.original.status ? formatOrderStatus(row.original.status) : '-'}
        {row.original.status === OrderStatus.REJECTED && row.original.code && (
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger>
                <Info size={14} />
              </TooltipTrigger>
              <TooltipContent>{ErrorMsgs[row.original.code]}</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
    ),
    meta: { width: 'w-[10%]' },
  },
  {
    accessorKey: 'orderType',
    header: 'ORDER TYPE',
    cell: ({ row }) => formatLpOrderType(row.original.orderType),
    meta: { width: 'w-[10%]' },
  },
  //   {
  //     accessorKey: 'timeLeft',
  //     cell: (info) => info.getValue(),
  //     header: 'TIME UNTIL WITHDRAWN',
  //     meta: { width: 'w-[12.5%]' },
  //   },
];

export default function HistoricalTransfersTable() {
  const { data: lpOrders } = useLpOrders({});

  const data = useMemo(() => {
    if (!lpOrders) return [];
    return lpOrders
      .filter((order) => order.status !== OrderStatus.NEW)
      .sort((a, b) => b.postTime - a.postTime);
  }, [lpOrders]);

  return <Table<LpOrder> data={data} columns={columns} />;
}
