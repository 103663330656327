import { useCallback } from 'react';
import {
  useWallet,
  useConnection,
  useAnchorWallet,
} from '@solana/wallet-adapter-react';
import { privateApi } from '../api-clients/rest-client';

import { USDC_MINT_ADDRESS } from '../wagmi/config';
import { parseDecimalToBigInt } from '@/utils/value-format';
import { COLLATERAL_DECIMALS } from '@/store/use-markets-store';

import { solanaAddressToHex } from '@/utils/solana';

import { toast } from 'sonner';
import { useFetchAccount } from '@/features/account/api/get-account';

import { getTransfers } from '@/features/account/api/get-transfers';
import { ErrorCode, ErrorMsgs, OrderStatus } from '@/types';
import { useAccountStore } from '@/store/use-account-store';
import { encodeAbiParameters, keccak256 } from 'viem';
import { useTransfersStore } from '@/store/use-transfers-store';
import { uint8ArrayToHex } from '../utils';
import { getAssociatedTokenAddress } from '@solana/spl-token';
import { PublicKey } from '@solana/web3.js';
import { signMessage } from 'viem/accounts';

export const useSolanaWithdraw = (_size: string) => {
  const size = parseDecimalToBigInt(
    _size.endsWith('.') ? `${_size}00` : _size,
    COLLATERAL_DECIMALS,
  );

  const { connection } = useConnection();
  const { publicKey } = useWallet();
  const wallet = useAnchorWallet();
  const { data: account, refetch } = useFetchAccount();

  const { getAuthParams, nextNonce, getAccurateTime } = useAccountStore(
    (state) => ({
      getAuthParams: state.getAuthParams,
      nextNonce: state.nextNonce,
      getAccurateTime: state.getAccurateTime,
    }),
  );

  const withdraw = useCallback(async () => {
    if (!publicKey || !wallet) {
      toast.error('Wallet is not connected.');
      return;
    }
    if (!account?.address) {
      toast.error(
        'No such registered address found. Retry connecting your wallet.',
      );
      return;
    }

    const authParams = getAuthParams();

    if (!authParams?.signingKey) {
      console.error('No signing key found');
      throw new Error('No signing key found');
    }

    const time = getAccurateTime();
    const nonce = nextNonce();

    const userTokenAccount = await getAssociatedTokenAddress(
      new PublicKey(USDC_MINT_ADDRESS),
      publicKey,
    );

    const encodedOrder = encodeAbiParameters(
      [
        { name: 'time', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'isDeposit', type: 'bool' },
        { name: 'account', type: 'address' },
        { name: 'recipient', type: 'bytes32' },
        { name: 'token', type: 'address' },
        { name: 'size', type: 'uint256' },
        { name: 'chainId', type: 'uint256' },
      ],
      [
        BigInt(time),
        BigInt(nonce),
        false,
        account.address as `0x${string}`,
        solanaAddressToHex(userTokenAccount.toString()) as `0x${string}`,
        '0xaf88d065e77c8cc2239327c5edb3a432268e5831' as `0x${string}`,
        BigInt(size),
        BigInt(0),
      ],
    );

    const signature = await signMessage({
      message: { raw: keccak256(encodedOrder) },
      privateKey: authParams.signingKey,
    });

    const body = {
      order: {
        deposit: false,
        size: Number(size),
        account: account.address,
        nonce,
        time,
        token: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
        recipient: userTokenAccount.toString(),
        chainId: 0,
      },
      signature,
    };

    const promise = privateApi.post('/transfer/withdraw', body);

    const toastPromise = promise.then((res) => {
      const { id: orderId } = res;
      return new Promise((resolve, reject) => {
        let pollIntervalId: NodeJS.Timeout;
        let subscriberTimeoutId: NodeJS.Timeout;
        let unsubscribe: () => void;

        const cleanup = () => {
          clearTimeout(subscriberTimeoutId);
          clearInterval(pollIntervalId);
          unsubscribe();
        };

        unsubscribe = useTransfersStore.subscribe((state, prevState) => {
          if (orderId in state.transfers) {
            cleanup();
            if (state.transfers[orderId].status === OrderStatus.NEW) {
              resolve(state.transfers[orderId]);
            } else if (
              state.transfers[orderId].status === OrderStatus.REJECTED
            ) {
              reject(state.transfers[orderId].code as ErrorCode);
            }
          }
        });

        subscriberTimeoutId = setTimeout(() => {
          cleanup();
          reject(ErrorCode.INTERNAL_CREATE_ORDER_TIMEOUT);
        }, 10_000); // 10 seconds timeout

        pollIntervalId = setInterval(() => {
          getTransfers({ id: orderId });
        }, 2_000);
      });
    });

    toast.promise(toastPromise, {
      loading: 'Requesting withdrawal...',
      success: 'Requested withdrawal successfully',
      error: (error) => {
        return `Withdraw failed: ${ErrorMsgs[error as ErrorCode]}`;
      },
    });
  }, [connection, publicKey, size, account, wallet, signMessage]);

  return { withdraw };
};
