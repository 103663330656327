import { queryOptions, useQuery } from '@tanstack/react-query';
import { QueryConfig } from '@/lib/api-clients/react-query';
import { privateApi } from '@/lib/api-clients/rest-client';
import { useAccountStore } from '@/store/use-account-store';
import { Order, useOrdersStore } from '@/store/use-orders-store';
import { OrderStatus, OrderType } from '@/types/enums';
import {
  COLLATERAL_DECIMALS,
  getAllMarketStoreStates,
  MarketStore,
} from '@/store/use-markets-store';
import { parseDecimalToBigInt } from '@/utils/value-format';
import { getUpdatedAccountOnFill } from '../utils/math';

interface GetOrdersParams {
  id?: string;
  symbol?: string;
  orderType?: OrderType;
  status?: OrderStatus;
  startTime?: number;
  endTime?: number;
  limit?: number;
}

export type NewResponse = {
  id: string;
  symbol: string;
  isBuy: boolean;
  orderType: OrderType;
  limitPrice: string;
  size: string;
  reduceOnly: boolean;
  status: OrderStatus.NEW;
  initMarginRatio: string;
  postTime: number;
};

export type FilledResponse = {
  id: string;
  symbol: string;
  isBuy: boolean;
  orderType: OrderType;
  limitPrice: string;
  size: string;
  reduceOnly: boolean;
  status: OrderStatus.FILLED;
  initMarginRatio: string;
  lastFilledSize: string;
  lastFilledPrice: string;
  lastFilledTime: number;
  avgFilledPrice: string;
  realizedPnl: string;
  settledFunding: string;
  fees: string;
  postTime: number;
};

export type CancelledResponse = {
  id: string;
  symbol: string;
  isBuy: boolean;
  orderType: OrderType;
  limitPrice: string;
  size: string;
  reduceOnly: boolean;
  status: OrderStatus.CANCELLED;
  initMarginRatio: string;
  postTime: number;
};

export type RejectedResponse = {
  id: string;
  symbol: string;
  isBuy: boolean;
  orderType: OrderType;
  limitPrice: string;
  size: string;
  reduceOnly: boolean;
  status: OrderStatus.REJECTED;
  initMarginRatio: string;
  postTime: number;
  code: number;
};

export type OrderResponse =
  | NewResponse
  | FilledResponse
  | CancelledResponse
  | RejectedResponse;

export const orderResponseToOrder = (
  orderResponse: OrderResponse,
  marketStores: Record<string, MarketStore>,
): Order => {
  const { marketSpec } = marketStores[orderResponse.symbol];

  switch (orderResponse.status) {
    case OrderStatus.NEW:
      return {
        ...orderResponse,
        size: parseDecimalToBigInt(orderResponse.size, marketSpec.sizeDecimals),
        limitPrice: parseDecimalToBigInt(
          orderResponse.limitPrice,
          marketSpec.priceDecimals,
        ),
        initMarginRatio: parseDecimalToBigInt(
          orderResponse.initMarginRatio,
          4n,
        ),
      };
    case OrderStatus.FILLED:
      return {
        ...orderResponse,
        size: parseDecimalToBigInt(orderResponse.size, marketSpec.sizeDecimals),
        limitPrice: parseDecimalToBigInt(
          orderResponse.limitPrice,
          marketSpec.priceDecimals,
        ),
        initMarginRatio: parseDecimalToBigInt(
          orderResponse.initMarginRatio,
          4n,
        ),
        lastFilledSize: parseDecimalToBigInt(
          orderResponse.lastFilledSize,
          marketSpec.sizeDecimals,
        ),
        lastFilledPrice: parseDecimalToBigInt(
          orderResponse.lastFilledPrice,
          marketSpec.priceDecimals,
        ),
        avgFilledPrice: parseDecimalToBigInt(
          orderResponse.avgFilledPrice,
          marketSpec.priceDecimals,
        ),
        realizedPnl: parseDecimalToBigInt(
          orderResponse.realizedPnl,
          COLLATERAL_DECIMALS,
        ),
        settledFunding: parseDecimalToBigInt(
          orderResponse.settledFunding,
          COLLATERAL_DECIMALS,
        ),
        fees: parseDecimalToBigInt(orderResponse.fees, COLLATERAL_DECIMALS),
      };
    case OrderStatus.CANCELLED:
      return {
        ...orderResponse,
        size: parseDecimalToBigInt(orderResponse.size, marketSpec.sizeDecimals),
        limitPrice: parseDecimalToBigInt(
          orderResponse.limitPrice,
          marketSpec.priceDecimals,
        ),
        initMarginRatio: parseDecimalToBigInt(
          orderResponse.initMarginRatio,
          4n,
        ),
      };
    case OrderStatus.REJECTED:
      return {
        ...orderResponse,
        size: parseDecimalToBigInt(orderResponse.size, marketSpec.sizeDecimals),
        limitPrice: parseDecimalToBigInt(
          orderResponse.limitPrice,
          marketSpec.priceDecimals,
        ),
        initMarginRatio: parseDecimalToBigInt(
          orderResponse.initMarginRatio,
          4n,
        ),
      };
  }
};

export const getOrders = async ({
  id,
  symbol,
  orderType,
  status,
  startTime,
  endTime = useAccountStore.getState().getAccurateTime(),
  limit = 1000,
}: GetOrdersParams): Promise<OrderResponse[]> => {
  return privateApi
    .get('/orders', {
      params: {
        id,
        symbol,
        orderType,
        status,
        startTime,
        endTime,
        limit,
      },
    })
    .then((res: OrderResponse[]) => {
      const marketStores = getAllMarketStoreStates();
      const { setOrder } = useOrdersStore.getState();

      res.forEach((orderResponse) => {
        const order = orderResponseToOrder(orderResponse, marketStores);
        setOrder(order);

        // Update account with filled order (primarily handle the case if ws misses a fill)
        const {
          account,
          accountLastUpdated,
          setAccount,
          setAccountLastUpdated,
        } = useAccountStore.getState();

        if (
          order.status === OrderStatus.FILLED &&
          order.lastFilledTime > accountLastUpdated
        ) {
          const updatedAccount = getUpdatedAccountOnFill(
            order.symbol,
            order.isBuy,
            order.lastFilledSize,
            order.lastFilledPrice,
            order.realizedPnl,
            order.initMarginRatio,
            account,
            marketStores[order.symbol].marketData,
          );
          setAccount(updatedAccount);
          setAccountLastUpdated(order.lastFilledTime);
        }
      });
      return res;
    });
};

export const getOrdersQueryOptions = (params: GetOrdersParams) => {
  const { endTime, limit, ...restOfParams } = params;
  const address = useAccountStore.getState().address;
  const paramsToCache = { ...restOfParams, address };
  return queryOptions({
    queryKey: ['Orders', paramsToCache],
    queryFn: () => getOrders(params),
  });
};

type UseOrdersOptions = GetOrdersParams & {
  queryConfig?: QueryConfig<typeof getOrdersQueryOptions>;
};

export const useOrders = ({
  queryConfig,
  ...params
}: UseOrdersOptions = {}) => {
  const hasAuth = useAccountStore((state) => state.hasAuth);
  return useQuery({
    enabled: hasAuth,
    ...getOrdersQueryOptions(params),
    ...queryConfig,
  });
};
