import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { truncateAddress } from '@/utils/format';
import { useAccountStore } from '@/store/use-account-store';
import {
  Connection,
  useAccount,
  useConnections,
  useSwitchAccount,
  useSwitchChain,
} from 'wagmi';
import { TransferDialog } from './modals/transfer-dialog';
import { TransferType } from '@/types/enums';
import { AccountMenu } from './account-menu';
import { Button } from '../ui';
import RegisterDialog from './modals/register-dialog';
import { MintingDialog } from './modals/minting-modal';
import { ConnectModal } from './modals/connect-modal';
import { AccountModal } from './modals/account-modal';
import { Chain } from 'viem';
import { useUnifiedAccount, useUnifiedConnections } from '@/hooks';
import { updatePrivateApiHeaders } from '@/lib/api-clients/rest-client';

interface IRKConnectButtonProps {}

export const RKConnectButton: React.FC<IRKConnectButtonProps> = ({}) => {
  const { chainId, connector } = useUnifiedAccount();
  const { isConnected, address, networkType } = useUnifiedAccount();

  const { chains, switchChain } = useSwitchChain();
  const {
    _hasHydrated,
    hasAuth,
    authHydrated,
    authParamsByAddress,
    getAuthParamsByAddress,
    setHasAuth,
  } = useAccountStore((state) => ({
    _hasHydrated: state._hasHydrated,
    hasAuth: state.hasAuth,
    authHydrated: state.authHydrated,
    authParamsByAddress: state.authParamsByAddress,
    getAuthParamsByAddress: state.getAuthParamsByAddress,
    setHasAuth: state.setHasAuth,
  }));
  // const connections = useConnections();
  const connections: Connection[] = [];
  const _connections = useUnifiedConnections();
  const { switchAccount } = useSwitchAccount();

  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isConnectModalOpen, setIsConnectModalOpen] = useState(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);

  useEffect(() => {
    const authParams = getAuthParamsByAddress(address);
    if (authParams) {
      if (address === authParams.address) {
        updatePrivateApiHeaders({
          xrestservermm: `restserver${authParams.accountGroup}`,
          'X-API-KEY': authParams.apiKey,
        });

        setHasAuth(true);
      }
    } else {
      // console.error('no auth params found, hasAuth set to false');
      setHasAuth(false);
    }
  }, [address]);

  useEffect(() => {
    if (isConnected && !hasAuth) {
      setIsRegisterModalOpen(true);
      setIsConnectModalOpen(false);
    } else if (isConnected && hasAuth) {
      setIsRegisterModalOpen(false);
      setIsConnectModalOpen(false);
    }
  }, [isConnected, hasAuth, isRegisterModalOpen]);

  useEffect(() => {
    if (
      isConnected &&
      chainId &&
      !chains.some((c: Chain) => c.id === chainId)
    ) {
      switchChain({ chainId: chains[0].id });
    }
  }, [chainId, isConnected]);

  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

  const menuOptions = useMemo(
    () =>
      connections
        .map((connection) => ({
          address: connection.accounts[0],
          connector: connection.connector,
        }))
        .map((account) => ({
          label: account.address,
          connector: account.connector,
          action: () => {
            switchAccount({
              connector: account.connector,
            });
          },
        })),
    [connections],
  );

  return (
    <div className="grid w-full">
      {!isConnected ? (
        <div className="flex w-full items-center justify-between">
          <Button
            className="ml-6 h-14 w-full rounded-none font-mono text-base font-normal uppercase tracking-wider"
            onClick={() => setIsConnectModalOpen(true)}
          >
            CONNECT YOUR WALLET
          </Button>
        </div>
      ) : (
        <div className="flex w-full justify-between gap-4">
          <div className="flex gap-2">
            <AccountMenu
              options={menuOptions}
              openAccountModal={() => setIsAccountModalOpen(true)}
              openConnectModal={() => setIsConnectModalOpen(true)}
            >
              <span className="flex items-center text-primary">
                WELCOME {truncateAddress(address as string, 6)}
              </span>
            </AccountMenu>
          </div>
          <div className="flex items-center gap-4">
            {import.meta.env.VITE_ENV === 'development' && !networkType && (
              <MintingDialog />
            )}
            <TransferDialog orderType={TransferType.DEPOSIT} />

            <TransferDialog
              orderType={TransferType.WITHDRAW}
              isOpen={isWithdrawModalOpen}
              onOpenChange={setIsWithdrawModalOpen}
            />

            <RegisterDialog
              open={isRegisterModalOpen}
              setOpen={setIsRegisterModalOpen}
              openAccountModal={() => setIsAccountModalOpen(true)}
            />
          </div>
        </div>
      )}
      <ConnectModal
        isOpen={isConnectModalOpen}
        onOpenChange={setIsConnectModalOpen}
      />
      <AccountModal
        isOpen={isAccountModalOpen}
        onOpenChange={setIsAccountModalOpen}
      />
    </div>
  );
};
