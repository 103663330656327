import { Table } from '@/components/ui';
import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useLpOrders } from '../api/get-lp-orders';
import { OrderStatus, LPOrderType } from '@/types/enums';
import { formatDate, formatNumber } from '@/utils/value-format';

type LpScheduleWithdraw = {
  postTime: string;
  size: string;
  timeLeft: string;
};

const EIGHT_HOURS = 60 * 60 * 8 * 1000;

function getHoursUntilWithdrawn(postTime: number): number {
  const postTimePlusEightHours = postTime + EIGHT_HOURS;

  const now = Date.now();

  const differenceInMilliseconds = postTimePlusEightHours - now;
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

  return Math.ceil(differenceInHours);
}

const columns: ColumnDef<LpScheduleWithdraw>[] = [
  {
    accessorKey: 'postTime',
    cell: (info) => info.getValue(),
    header: 'TIME',
    meta: { width: 'w-[10%]' },
  },
  {
    accessorKey: 'size',
    cell: (info) => info.getValue(),
    header: 'REQUESTED WTTHDRAW AMOUNT',
    meta: { width: 'w-[12.5%]' },
  },
  {
    accessorKey: 'timeLeft',
    cell: (info) => info.getValue(),
    header: 'TIME UNTIL WITHDRAWN',
    meta: { width: 'w-[12.5%]' },
  },
];

export default function PendingWithdrawalsTable() {
  const { data: lpOrders } = useLpOrders({
    orderType: LPOrderType.SCHEDULE_WITHDRAW,
    status: OrderStatus.NEW,
  });

  const data = useMemo(() => {
    if (!lpOrders) return [];
    return lpOrders
      .sort((a, b) => a.postTime - b.postTime)
      .map((order) => {
        return {
          size: formatNumber(order.size, { maxDigits: 2 }),
          postTime: formatDate(new Date(order.postTime)),
          timeLeft: `${getHoursUntilWithdrawn(order.postTime)} hours`,
        };
      });
  }, [lpOrders]);

  return <Table<LpScheduleWithdraw> data={data} columns={columns} />;
}
