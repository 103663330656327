import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { privateApi } from '@/lib/api-clients/rest-client';
import { MutationConfig } from '@/lib/api-clients/react-query';
import { useAccountStore } from '@/store/use-account-store';
import { signMessage } from 'viem/accounts';
import { encodeAbiParameters, keccak256 } from 'viem';
import {
  FilledOrder,
  NewOrder,
  useOrdersStore,
} from '@/store/use-orders-store';
import { ErrorCode, ErrorMsgs, OrderStatus, OrderType } from '@/types/enums';
import { getOrders } from '@/features/account/api/get-orders';
import { abbrFromSym } from '@/utils/token-symbol';
import { useMarketStore } from '@/store/use-markets-store';
import { formatBigInt } from '@/utils/value-format';
import { toast } from 'sonner';
import { fetchTime } from '@/lib/api/get-time';

export const createOrderInputSchema = z.object({
  symbol: z.string(),
  isBuy: z.boolean(),
  size: z
    .string()
    .regex(/^[0-9]\d*(\.\d+)?$/, 'Must be a positive number')
    .regex(/^(?!0*\.?0+$)/, 'Must not be zero or all zeros'),
  orderType: z.nativeEnum(OrderType),
  limitPrice: z
    .string()
    .regex(/^[0-9]\d*(\.\d+)?$/, 'Must be a positive number')
    .regex(/^(?!0*\.?0+$)/, 'Must not be zero or all zeros'),
  reduceOnly: z.boolean().optional(),
  initMarginRatio: z.string().optional(),
});

export type CreateOrderInput = z.infer<typeof createOrderInputSchema>;

export type CreateOrderResponse = {
  id: string;
  status: OrderStatus;
};

export const createOrder = async ({
  order,
  showToast,
  waitForConfirm = false,
  useSystemTime = true,
}: {
  order: CreateOrderInput;
  showToast: boolean;
  waitForConfirm?: boolean;
  useSystemTime?: boolean;
}): Promise<CreateOrderResponse> => {
  const { getAuthParams, nextNonce } = useAccountStore.getState();
  const authParams = getAuthParams();

  if (!authParams?.signingKey) {
    console.error('No signing key found');
    throw new Error('No signing key found');
  }

  const time = useAccountStore.getState().getAccurateTime();
  const nonce = nextNonce();

  const encodedOrder = encodeAbiParameters(
    [
      { name: 'time', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'orderType', type: 'string' },
      { name: 'symbol', type: 'string' },
      { name: 'isBuy', type: 'bool' },
      { name: 'size', type: 'string' },
      { name: 'limitPrice', type: 'string' },
      { name: 'reduceOnly', type: 'bool' },
    ],
    [
      BigInt(time),
      BigInt(nonce),
      order.orderType,
      order.symbol,
      order.isBuy,
      order.size,
      order.limitPrice,
      order.reduceOnly ?? false, // TODO: dangerous?
    ],
  );

  const signature = await signMessage({
    message: { raw: keccak256(encodedOrder) },
    privateKey: authParams.signingKey,
  });

  const body = {
    order: { ...order, nonce, time },
    signature,
    recvWindow: 10_000,
  };

  const restPromise = privateApi.post('/orders', body);

  const confirmPromise = restPromise.then((res) => {
    const { id: orderId } = res;
    return new Promise<FilledOrder | NewOrder>((resolve, reject) => {
      let subscriberTimeoutId: NodeJS.Timeout;
      let unsubscribe: () => void;

      const cleanup = () => {
        clearTimeout(subscriberTimeoutId);
        unsubscribe();
      };

      const successStatus =
        order.orderType === OrderType.MARKET
          ? OrderStatus.FILLED
          : OrderStatus.NEW;

      unsubscribe = useOrdersStore.subscribe((state) => {
        if (orderId in state.orders) {
          const order = state.orders[orderId];
          if (order.status === successStatus) {
            cleanup();
            resolve(order);
            return;
          }

          if (order.status === OrderStatus.REJECTED) {
            cleanup();
            reject(order.code as ErrorCode);
            return;
          }
        }
      });

      subscriberTimeoutId = setTimeout(() => {
        cleanup();
        reject(ErrorCode.INTERNAL_CREATE_ORDER_TIMEOUT);
      }, 10_000); // 10 seconds timeout

      useOrdersStore.getState().pollOrders([[orderId, successStatus, time]]);
    });
  });

  if (showToast) {
    toast.promise(confirmPromise, {
      loading: `${order.orderType === OrderType.MARKET ? 'Executing' : 'Adding'} order...`,
      success: (data: FilledOrder | NewOrder) => {
        const { marketSpec } = useMarketStore(data.symbol).getState();
        if (data.orderType === OrderType.MARKET) {
          const { symbol, lastFilledPrice, lastFilledSize } =
            data as FilledOrder;
          return `Market order executed at ${formatBigInt(lastFilledPrice, marketSpec.priceDecimals)} for ${formatBigInt(lastFilledSize, marketSpec.sizeDecimals)} ${abbrFromSym(symbol)}`;
        } else {
          const { limitPrice, size, symbol } = data as NewOrder;
          return `Limit order added at ${formatBigInt(limitPrice, marketSpec.priceDecimals)} for ${formatBigInt(size, marketSpec.sizeDecimals)} ${abbrFromSym(symbol)}`;
        }
      },
      error: (error) => {
        if (error == ErrorCode.ORDER_EXPIRED && useSystemTime) {
          createOrder({
            order,
            showToast,
            waitForConfirm,
            useSystemTime: false,
          });

          return `Order rejected: ${ErrorMsgs[error as ErrorCode]} Retrying with accurate time obtained from reliable third-party sources.`;
        }

        return `Order rejected: ${ErrorMsgs[error as ErrorCode]}`;
      },
    });

    // toastPromise(confirmPromise, {
    //   loading: {
    //     msg: `Order ${order.orderType === OrderType.MARKET ? 'executing' : 'added'}`,
    //   },
    //   success: {
    //     msg: (data: FilledOrder | NewOrder) => {
    //       const { marketSpec } = useMarketStore(data.symbol).getState();
    //       if (data.orderType === OrderType.MARKET) {
    //         const { symbol, lastFilledPrice, lastFilledSize } =
    //           data as FilledOrder;
    //         return `Market order executed at ${formatBigInt(lastFilledPrice, marketSpec.priceDecimals, { digits: 2 })} for ${formatBigInt(lastFilledSize, marketSpec.sizeDecimals)} ${abbrFromSym(symbol)}`;
    //       } else {
    //         const { limitPrice } = data as NewOrder;
    //         return `Limit order added at ${formatBigInt(limitPrice, marketSpec.priceDecimals)}`;
    //       }
    //     },
    //   },
    //   error: {
    //     msg: (error) => {
    //       return `Order rejected: ${ErrorMsgs[error as ErrorCode]}`;
    //     },
    //   },
    // });
  }

  return waitForConfirm ? confirmPromise : restPromise;
};

type UseCreateOrderOptions = {
  mutationConfig?: MutationConfig<typeof createOrder>;
};

export const useCreateOrder = ({
  mutationConfig,
}: UseCreateOrderOptions = {}) => {
  const { onSuccess, ...restConfig } = mutationConfig || {};
  return useMutation({
    onSuccess: (data, variables, context) => {},
    ...restConfig,
    mutationFn: createOrder,
  });
};
