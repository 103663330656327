import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { lazy, Suspense } from 'react';
import {
  bigIntToDecimalStr,
  formatBigInt,
  parseDecimalToBigInt,
  validatedDecimalStr,
} from '@/utils/value-format';
import { useEffect, useMemo, useState } from 'react';
import { getBalance, getChains, switchChain } from '@wagmi/core';
import { wagmiConfig } from '@/lib/wagmi/config';
import { useAccount } from 'wagmi';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { createDeposit } from '@/lib/api/create-deposit';
import { LPOrderType, OrderStatus } from '@/types/enums';
import {
  COLLATERAL_DECIMALS,
  useMarketStores,
} from '@/store/use-markets-store';
import { getAvailableFunds } from '@/features/account/utils/math';
import { useOrdersStore } from '@/store/use-orders-store';
import { useAccountStore } from '@/store/use-account-store';
import { cn } from '@/lib/utils';
import { useShallow } from 'zustand/react/shallow';
import { getUSDCAddress } from '@/utils/addresses';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  getAccountQueryOptions,
  useFetchAccount,
} from '@/features/account/api/get-account';
import { useCreateLpOrder } from '../api/create-lp-order';
import { useQueryClient } from '@tanstack/react-query';
import { useOrders } from '@/features/account/api/get-orders';
import { useLpOrders } from '../api/get-lp-orders';
import { useUnifiedAccount } from '@/hooks';

const lpFormSchema = z.object({
  orderType: z.nativeEnum(LPOrderType),
  size: z.bigint().min(1n),
});

type LPFormValues = z.infer<typeof lpFormSchema>;

interface TransferDialogProps {
  orderType: LPOrderType;
  children: React.ReactNode;
}

const LazyTransferDialogContent = lazy(() =>
  Promise.resolve({
    default: LPTransferDialogContent,
  }),
);

export const LPTransferDialog = ({
  orderType,
  children,
}: TransferDialogProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      {isOpen && (
        <Suspense fallback={null}>
          <LazyTransferDialogContent
            orderType={orderType}
            onOpenChange={(open) => setIsOpen(open)}
          />
        </Suspense>
      )}
    </Dialog>
  );
};

interface TransferDialogContentProps
  extends Pick<TransferDialogProps, 'orderType'> {
  onOpenChange: (open: boolean) => void;
}

export function LPTransferDialogContent({
  orderType,
  onOpenChange,
}: TransferDialogContentProps) {
  const [chainId, setChainId] = useState('balance');

  const { address, networkType } = useUnifiedAccount() as {
    address: `0x${string}`;
    networkType: number;
  };

  const { data: fetchedAccount } = useFetchAccount();

  const [maxAmount, setMaxAmount] = useState(BigInt(0));

  const form = useForm<LPFormValues>({
    resolver: zodResolver(lpFormSchema),
    defaultValues: {
      orderType,
      size: 0n,
    },
  });

  const size = form.watch('size');

  const availableToWithdraw = fetchedAccount
    ? parseDecimalToBigInt(fetchedAccount.lp.balance, COLLATERAL_DECIMALS)
    : 0n;

  const { account } = useAccountStore((state) => ({
    account: state.account,
  }));

  const markets = useMarketStores((state) => ({
    marketData: state.marketData,
    marketSpec: state.marketSpec,
  }));

  const newOrders = useOrdersStore(
    useShallow((state) =>
      Array.from(state.newOrderIds, (id) => state.orders[id]),
    ),
  );

  const balance = useMemo(
    () => getAvailableFunds(account, markets, newOrders).bigint,
    [account, markets, newOrders],
  );

  useEffect(() => {
    if (orderType !== LPOrderType.DEPOSIT) {
      setMaxAmount(availableToWithdraw);
    } else {
      (async () => {
        setMaxAmount(0n);
        let currentBalance: bigint;
        if (chainId === 'balance') {
          // TODO: If chainId is "balance" and isLiquidityProviding = true, check for Vest balance instead of network balance
          currentBalance = balance;
        } else {
          if (!address) return;
          currentBalance = (
            await getBalance(wagmiConfig, {
              address,
              blockTag: 'latest',
              token: getUSDCAddress(Number(chainId)),
              chainId: Number(chainId),
            })
          ).value;
        }
        setMaxAmount(currentBalance);
      })();
    }
  }, [address, orderType, chainId, balance]);

  const [sizeInput, setSizeInput] = useState<string>('');

  const { mutateAsync: createLPOrder } = useCreateLpOrder();

  const { isSubmitting, isValid } = form.formState;

  const onSubmit = async (values: LPFormValues) => {
    if (orderType === LPOrderType.DEPOSIT) {
      if (chainId !== 'balance') {
        await switchChain(wagmiConfig, { chainId: Number(chainId) });
        await createDeposit(
          {
            size: values.size,
            chainId: Number(chainId),
          },
          { showToast: false },
        );
      }
      await createLPOrder({
        orderType: values.orderType,
        size: bigIntToDecimalStr(values.size, COLLATERAL_DECIMALS),
      });
    } else {
      if (availableToWithdraw === 0n || !fetchedAccount?.lp.shares) {
        throw new Error('No available funds to withdraw');
      }
      const sizeInShares =
        (((values.size * 10n ** COLLATERAL_DECIMALS) / availableToWithdraw) *
          parseDecimalToBigInt(
            fetchedAccount?.lp.shares,
            COLLATERAL_DECIMALS,
          )) /
        10n ** COLLATERAL_DECIMALS;
      await createLPOrder({
        orderType: values.orderType,
        size: bigIntToDecimalStr(sizeInShares, COLLATERAL_DECIMALS),
      });
    }
    form.reset();
    setSizeInput('');
    onOpenChange(false);
  };

  const chains = getChains(wagmiConfig);

  return (
    <DialogContent
      className={cn(
        'gap-0 border border-border bg-background p-0 sm:max-w-md sm:rounded-none',
      )}
    >
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, (errors) => {
            console.error('Form validation errors:', errors);
          })}
        >
          <DialogHeader className="px-5 pt-6">
            <DialogTitle className="text-xl text-white">
              {orderType === LPOrderType.DEPOSIT
                ? 'Provide Liquidity'
                : 'Withdraw Liquidity'}
            </DialogTitle>
          </DialogHeader>
          <div className="flex flex-col gap-1.5 px-5 py-6">
            <div className="mb-3 flex h-10 items-center gap-3">
              <Label
                htmlFor="from"
                className="text-left text-base text-vestgrey-100"
              >
                {orderType === LPOrderType.DEPOSIT ? 'From' : 'To'}
              </Label>
              <Select value={chainId} onValueChange={(v) => setChainId(v)}>
                <SelectTrigger className="h-10">
                  <SelectValue placeholder="Select source" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="balance">
                    <svg
                      height="12"
                      viewBox="0 0 22 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mr-2 inline-block"
                    >
                      <path
                        d="M4.9982 0.576904L0.0531 4.4976C-0.0177 4.5538 -0.0177 4.6605 0.0531 4.7167L10.4533 12.9627C10.505 13.0035 10.5787 13.0035 10.6304 12.9627L21.0305 4.7167C21.1014 4.6605 21.1014 4.5538 21.0305 4.4976L16.0855 0.576904C15.9927 0.503104 15.8553 0.568405 15.8553 0.686405V13.3138C15.8553 13.3911 15.7915 13.4543 15.7136 13.4543H5.3701C5.2922 13.4543 5.2284 13.3911 5.2284 13.3138V0.686405C5.2284 0.568405 5.091 0.503104 4.9982 0.576904Z"
                        fill="white"
                      />
                    </svg>
                    Account Balance
                  </SelectItem>
                  {!networkType &&
                    chains.map((chain) => (
                      <SelectItem
                        key={chain.id}
                        disabled={orderType !== LPOrderType.DEPOSIT}
                        value={chain.id.toString()}
                      >
                        {chain.name}{' '}
                        {orderType !== LPOrderType.DEPOSIT && '(Coming Soon)'}
                      </SelectItem>
                    ))}
                </SelectContent>
              </Select>
            </div>
            <div className="mb-3 flex flex-col gap-3">
              <Label
                htmlFor="amount"
                className="text-left text-base text-vestgrey-100"
              >
                {orderType === LPOrderType.DEPOSIT ? 'Deposit' : 'Withdraw'}{' '}
                amount {orderType === LPOrderType.DEPOSIT && '(Min 1 USDC)'}
              </Label>
              <div className="grid grid-cols-[1fr_max-content_max-content] items-center gap-3">
                <Controller
                  control={form.control}
                  name="size"
                  render={({ field }) => (
                    <Input
                      {...field}
                      id="size"
                      className="rounded-none bg-vestgrey-800 font-mono text-base"
                      type="text"
                      value={sizeInput}
                      onChange={(e) => {
                        try {
                          const valueStr = validatedDecimalStr(
                            e.target.value,
                            2,
                            10,
                          );
                          const value = parseDecimalToBigInt(
                            valueStr,
                            COLLATERAL_DECIMALS,
                          );
                          if (value <= maxAmount) {
                            field.onChange(value);
                            setSizeInput(valueStr);
                          }
                        } catch {}
                      }}
                    />
                  )}
                />
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_34_356)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.98108 0.5C15.4935 0.5 19.9622 4.96867 19.9622 10.4811C19.9622 15.9935 15.4935 20.4622 9.98108 20.4622C4.46867 20.4622 0 15.9935 0 10.4811C0 4.96867 4.46867 0.5 9.98108 0.5Z"
                      fill="#2775CA"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.2615 3.45891C12.0364 3.38701 11.8524 3.5206 11.8524 3.7569V4.33803C11.8524 4.49647 11.9718 4.6768 12.1206 4.73132C14.5096 5.60633 16.2191 7.90261 16.2191 10.591C16.2191 13.2794 14.5096 15.5756 12.1206 16.4506C11.9574 16.5104 11.8524 16.6702 11.8524 16.844V17.4251C11.8524 17.6613 12.0364 17.795 12.2615 17.723C15.2806 16.7584 17.4667 13.93 17.4667 10.591C17.4667 7.25196 15.2806 4.42352 12.2615 3.45891ZM8.10949 3.7569C8.10949 3.5206 7.92545 3.38701 7.70039 3.45891C4.68129 4.42352 2.49512 7.25192 2.49512 10.591C2.49512 13.9301 4.68129 16.7584 7.70039 17.723C7.92545 17.795 8.10949 17.6613 8.10949 17.4251V16.844C8.10949 16.6855 7.99007 16.5051 7.8413 16.4506C5.45233 15.5756 3.74275 13.2794 3.74275 10.591C3.74275 7.90265 5.45233 5.60633 7.8413 4.73132C7.99007 4.6768 8.10949 4.49647 8.10949 4.33803V3.7569ZM10.2928 5.60046H9.66905C9.49678 5.60046 9.35713 5.74008 9.35713 5.91234V6.87866C8.12045 7.05449 7.32099 7.87748 7.32099 8.93328C7.32099 10.2999 8.14715 10.8302 9.89094 11.0648C11.074 11.2586 11.4107 11.5134 11.4107 12.1867C11.4107 12.8597 10.8393 13.3085 10.0339 13.3085C8.94481 13.3085 8.58643 12.8319 8.45425 12.2151C8.42294 12.069 8.2978 11.9622 8.14836 11.9622H7.43685C7.25762 11.9622 7.11749 12.1236 7.14829 12.3002C7.32948 13.3384 7.99626 14.1001 9.35713 14.2848V15.2696C9.35713 15.4419 9.49678 15.5815 9.66905 15.5815H10.2928C10.4651 15.5815 10.6048 15.4419 10.6048 15.2696V14.2843C11.893 14.0796 12.7161 13.1828 12.7161 12.0744C12.7161 10.6161 11.8288 10.1265 10.1153 9.89201C8.85068 9.7084 8.60608 9.41257 8.60608 8.82102C8.60608 8.26024 9.03429 7.86233 9.86045 7.86233C10.6077 7.86233 11.0374 8.12313 11.215 8.7245C11.2546 8.85871 11.3747 8.95359 11.5146 8.95359H12.1708C12.3538 8.95359 12.4962 8.78544 12.4571 8.60664C12.2494 7.65629 11.6089 7.086 10.6048 6.9061V5.91234C10.6048 5.74008 10.4651 5.60046 10.2928 5.60046Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_34_356">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <button
                  // className="h-full bg-vestgrey-800 px-3 uppercase"
                  className="h-10 bg-black-alt px-4 py-2 font-mono text-sm uppercase"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    form.setValue('size', maxAmount, {
                      shouldValidate: true,
                    });
                    setSizeInput(
                      formatBigInt(maxAmount, COLLATERAL_DECIMALS, {
                        digits: 2,
                      }),
                    );
                  }}
                >
                  MAX
                </button>
              </div>
            </div>
            <div className="flex h-10 items-center justify-between">
              <p className="text-base text-vestgrey-100">Available</p>
              <p className="text-font flex font-mono">
                {formatBigInt(maxAmount, COLLATERAL_DECIMALS, { digits: 2 })}
              </p>
            </div>
            <div className="flex h-10 items-center justify-between">
              <p className="text-base text-vestgrey-100">
                {orderType === LPOrderType.DEPOSIT
                  ? 'Deposit Time'
                  : 'Withdraw Time'}
              </p>
              <p className="text-font flex font-mono">
                {orderType === LPOrderType.DEPOSIT
                  ? '10 seconds'
                  : size >= BigInt(25_000) * 10n ** COLLATERAL_DECIMALS
                    ? '24 hours'
                    : '8 hours'}
              </p>
            </div>
          </div>
          <DialogFooter>
            <Button
              type="submit"
              className="h-14 w-full rounded-none font-mono text-lg font-normal uppercase tracking-wider"
              disabled={isSubmitting || !isValid || Number(sizeInput) < 1}
            >
              {orderType === LPOrderType.DEPOSIT
                ? isSubmitting && chainId !== 'balance'
                  ? 'Confirm in Wallet'
                  : 'Provide Liquidity'
                : 'Withdraw Liquidity'}
            </Button>
          </DialogFooter>
        </form>
      </FormProvider>
    </DialogContent>
  );
}
