// src/features/transfer/api/create-withdraw.ts

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import { AuthParams, Entity } from '@/types/api';
import { privateApi } from '../api-clients/rest-client';
import { MutationConfig } from '../api-clients/react-query';
import { encodeAbiParameters, keccak256 } from 'viem';
import { useAccountStore } from '@/store/use-account-store';
import { signMessage } from 'viem/accounts';
import { ErrorCode, ErrorMsgs, OrderStatus } from '@/types/enums';
import { useTransfersStore } from '@/store/use-transfers-store';
import { getTransfers } from '@/features/account/api/get-transfers';
import { toast } from 'sonner';
import { Link } from 'react-router-dom';
import { router } from '@/app/router';

export const createWithdrawInputSchema = z.object({
  token: z.string(),
  size: z.bigint(),
  chainId: z.number(),
});

export type CreateWithdrawParams = z.infer<typeof createWithdrawInputSchema>;

export type CreateWithdrawResponse = Entity<{
  id: string;
  status: string; // NEW
}>;

const createWithdraw = async (
  data: CreateWithdrawParams,
): Promise<CreateWithdrawResponse> => {
  const { getAuthParams, nextNonce } = useAccountStore.getState();
  const authParams = getAuthParams();

  if (!authParams?.signingKey) {
    console.error('No signing key found');
    throw new Error('No signing key found');
  }

  const time = useAccountStore.getState().getAccurateTime();
  const nonce = nextNonce();

  const encodedOrder = encodeAbiParameters(
    [
      { name: 'time', type: 'uint256' },
      { name: 'nonce', type: 'uint256' },
      { name: 'isDeposit', type: 'bool' },
      { name: 'account', type: 'address' },
      { name: 'recipient', type: 'address' },
      { name: 'token', type: 'address' },
      { name: 'size', type: 'uint256' },
      { name: 'chainId', type: 'uint256' },
    ],
    [
      BigInt(time),
      BigInt(nonce),
      false,
      authParams.address as `0x${string}`,
      authParams.address as `0x${string}`,
      data.token as `0x${string}`,
      data.size,
      BigInt(data.chainId),
    ],
  );

  const signature = await signMessage({
    message: { raw: keccak256(encodedOrder) },
    privateKey: authParams.signingKey,
  });

  const body = {
    order: {
      ...data,
      size: Number(data.size),
      nonce,
      time,
      recipient: authParams.address,
    },
    signature,
  };

  const promise = privateApi.post('/transfer/withdraw', body);

  const toastPromise = promise.then((res) => {
    const { id: orderId } = res;
    return new Promise((resolve, reject) => {
      let pollIntervalId: NodeJS.Timeout;
      let subscriberTimeoutId: NodeJS.Timeout;
      let unsubscribe: () => void;

      const cleanup = () => {
        clearTimeout(subscriberTimeoutId);
        clearInterval(pollIntervalId);
        unsubscribe();
      };

      unsubscribe = useTransfersStore.subscribe((state, prevState) => {
        if (orderId in state.transfers) {
          cleanup();
          if (state.transfers[orderId].status === OrderStatus.NEW) {
            resolve(state.transfers[orderId]);
          } else if (state.transfers[orderId].status === OrderStatus.REJECTED) {
            reject(state.transfers[orderId].code as ErrorCode);
          }
        }
      });

      subscriberTimeoutId = setTimeout(() => {
        cleanup();
        reject(ErrorCode.INTERNAL_CREATE_ORDER_TIMEOUT);
      }, 10_000); // 10 seconds timeout

      pollIntervalId = setInterval(() => {
        getTransfers({ id: orderId });
      }, 2_000);
    });
  });

  toast.promise(toastPromise, {
    loading: 'Requesting withdrawal...',
    success: (
      <span>
        Successfully requested withdrawal: see{' '}
        <button
          className="underline"
          onClick={() =>
            router.navigate({
              pathname: window.location.pathname.includes('/trade/')
                ? window.location.pathname
                : '/trade/ETH-PERP',
              search: '?view=transfers',
            })
          }
        >
          Transfers
        </button>
      </span>
    ),
    error: (error) => `Withdraw failed: ${ErrorMsgs[error as ErrorCode]}`,
  });

  return promise;
};

type UseCreateWithdrawOptions = {
  mutationConfig?: MutationConfig<typeof createWithdraw>;
};

export const useCreateWithdraw = ({
  mutationConfig,
}: UseCreateWithdrawOptions = {}) => {
  const queryClient = useQueryClient();

  const { onSuccess, ...restConfig } = mutationConfig || {};

  return useMutation({
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: ['withdrawOrders'],
      });
      onSuccess?.(...args);
    },
    ...restConfig,
    mutationFn: createWithdraw,
  });
};
