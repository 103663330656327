import { createConfig, http } from 'wagmi';

import config from '@/config';
import { metaMask, walletConnect, coinbaseWallet } from 'wagmi/connectors';
import {
  zkSyncSepoliaTestnet,
  arbitrum,
  mainnet,
  base,
  optimism,
  polygon,
  zksync,
  Chain,
} from 'wagmi/chains';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

// WalletConnect Project ID
const { WALLET_CONNECT_PROJECT_ID: projectId, isDevelopment } = config;

// Define supported chains
export const chains: readonly [Chain, ...Chain[]] = isDevelopment
  ? [zkSyncSepoliaTestnet]
  : [arbitrum, mainnet, base, optimism, polygon, zksync];

const transports = Object.fromEntries(
  chains.map((chain) => [chain.id, http()]),
);

export const wagmiConfig = createConfig({
  chains: chains,
  connectors: [metaMask(), walletConnect({ projectId }), coinbaseWallet()],
  transports,
});

// Solana Related
export const solNetwork = WalletAdapterNetwork.Mainnet;

export const USDC_MINT_ADDRESS = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v';

export const MINT_RECIPIENT = isDevelopment
  ? `0B3b31Da21Ca84068be498394c64AD8447495e14`
  : `80c526d1c2fddadb3cd39810cd7a79e07b0eda00`;

export const SOLANA_CHAIN_ID = 42161;
