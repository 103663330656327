import { FC, useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui';
import { ButtonWallet } from '@/features/account/components';
import { getConnectorIcon } from '@/lib/utils';
import { useUnifiedConnectors } from '@/hooks';
import { Spinner } from '@/components/ui/spinner';

interface ConnectModalProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  displayTrigger?: boolean;
}

export const ConnectModal: FC<ConnectModalProps> = ({
  isOpen: externalIsOpen,
  onOpenChange = () => {},
  displayTrigger = true,
}) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const { connectors, isPending } = useUnifiedConnectors();

  const isControlled = externalIsOpen !== undefined;
  const isOpen = isControlled ? externalIsOpen : internalIsOpen;

  useEffect(() => {
    if (isControlled) {
      setInternalIsOpen(externalIsOpen);
    }
  }, [externalIsOpen, isControlled]);

  const handleOpenChange = (newIsOpen: boolean) => {
    if (!isControlled) {
      setInternalIsOpen(newIsOpen);
    }
    onOpenChange?.(newIsOpen);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="gap-0 border border-border bg-background p-0 sm:max-w-md sm:rounded-none">
        {isPending && (
          <div className="absolute z-10 flex h-full w-full items-center justify-center rounded-lg bg-black bg-opacity-50">
            <Spinner size="lg" />
          </div>
        )}
        <DialogHeader className="px-5 pt-6">
          <DialogTitle className="text-xl text-foreground">
            Connect Wallet
          </DialogTitle>
        </DialogHeader>

        <div className="relative grid grid-cols-2 gap-4 p-5">
          {connectors.map(({ connector, handleConnect }) => (
            <ButtonWallet
              key={connector.name}
              icon={
                connector.icon ? connector.icon : getConnectorIcon(connector)
              }
              label={connector.name}
              onClick={handleConnect}
            />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
