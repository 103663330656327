import config from '@/config';
import { WsMsg } from '@/types';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import MetamaskIcon from '@/assets/branding/metamask.svg';
import WalletConnectIcon from '@/assets/branding/walletconnect.svg';
import CoinbaseWalletIcon from '@/assets/branding/coinbasewallet.svg';
import RabbyIcon from '@/assets/branding/rabby.svg';
import VestIcon from '@/assets/branding/vest-icon.svg';
import SafeIcon from '@/assets/branding/safe.svg';
import { Connector } from 'wagmi';
import { Adapter } from '@solana/wallet-adapter-base';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const parseWsMsg = async (event: MessageEvent<any>): Promise<WsMsg> => {
  try {
    const arrayBuffer = await event.data.arrayBuffer();
    const text = new TextDecoder().decode(arrayBuffer);
    return JSON.parse(text);
  } catch (e) {
    throw new Error(
      `WebSocket received message format error:\n${e}\nMessage: ${event.data}`,
    );
  }
};

export const logConsole = (isError: boolean, ...params: any[]): void => {
  const { isDevelopment } = config;

  if (isDevelopment)
    isError ? console.error(...params) : console.log(...params);
};

export const getConnectorIcon = (connector: Connector | Adapter) => {
  switch (connector.name) {
    case 'MetaMask':
      return MetamaskIcon;
    case 'WalletConnect':
      return WalletConnectIcon;
    case 'Coinbase Wallet':
      return CoinbaseWalletIcon;
    case 'Safe':
      return SafeIcon;
    // case 'Rabby Wallet':
    //   return RabbyIcon;
    default:
      return VestIcon;
  }
};

export function uint8ArrayToHex(uint8Array: Uint8Array): string {
  return Array.from(uint8Array)
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');
}
