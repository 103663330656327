import { Info } from 'lucide-react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import * as React from 'react';

import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useRegisterUser } from '@/lib/api/register-user';
import { Checkbox } from '../../ui';
import { Label } from '../../ui/label';
import { useState } from 'react';
import { toast } from 'sonner';
import { useLocation } from 'react-router-dom';
import postJoin from '@/features/referrals/api/post-join';
import { ErrorCode, ErrorMsgs, ErrorResponse } from '@/types';
import { useUnifiedAccount } from '@/hooks';

interface IRegisterDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  openAccountModal: () => void;
}

const RegisterDialog: React.FC<IRegisterDialogProps> = ({
  open,
  setOpen,
  openAccountModal,
}) => {
  const { address } = useUnifiedAccount();

  const { mutateAsync: registerUser, isPending: isRegistering } =
    useRegisterUser();

  const [rememberMe, setRememberMe] = useState(true);

  const handleRegister = async () => {
    if (address) {
      try {
        await registerUser({ address, rememberMe });
        setOpen(false);
        const refCode = localStorage.getItem('ref-code');
        if (!refCode) return;
        await postJoin(refCode);
      } catch (error) {
        console.error(error);
        const {
          response: {
            data: {
              detail: { code },
            },
          },
        } = error as ErrorResponse;

        if (code in ErrorMsgs)
          toast.error(`Registration failed - ${ErrorMsgs[code]}`);
        else toast.error('Registration failed');
      } finally {
        localStorage.removeItem('ref-code');
      }
    } else {
      toast.error('Please connect your wallet first');
    }
  };

  const { pathname } = useLocation();
  if (pathname === '/privacy' || pathname === '/terms') {
    return null;
  }

  return (
    <Dialog open={open}>
      <DialogContent
        hideCloseButton
        className="gap-0 border border-border bg-background p-0 sm:max-w-md sm:rounded-none"
      >
        <DialogHeader className="px-5 pt-6">
          <DialogTitle className="text-xl text-foreground">Sign In</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col gap-6 px-5 pb-10 pt-6">
          <p className="leading-relaxed text-vestgrey-100">
            Sign a message that securely links your wallet to a randomly
            generated signing key and an API key. By signing this message, you
            also acknowledge and agree to our{' '}
            <a className="underline" href={'/terms'} target="_blank">
              Terms of Service
            </a>{' '}
            and{' '}
            <a className="underline" href={'/privacy'} target="_blank">
              Privacy Policy
            </a>
            .
          </p>
          <div className="flex items-center gap-3">
            <Checkbox
              className="h-5 w-5"
              id="remember-me"
              checked={rememberMe}
              onCheckedChange={(checked) => setRememberMe(checked === true)}
            />
            <Label
              htmlFor="remember-me"
              className="text-base text-vestgrey-100"
            >
              Remember Me
            </Label>
            <TooltipProvider>
              <Tooltip delayDuration={0}>
                <TooltipTrigger className="text-vestgrey-100">
                  <Info size={14} />
                </TooltipTrigger>
                <TooltipContent>
                  Checking this box will securely save your signing and API key
                  in your browser's local storage. This allows you to start
                  trading without having to sign this message again.
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        <DialogFooter className="sm:flex-col sm:gap-0 sm:space-x-0">
          <Button
            type="button"
            onClick={handleRegister}
            className="h-14 w-full rounded-none font-mono text-lg font-normal tracking-wider"
            disabled={isRegistering}
          >
            {isRegistering ? 'CONFIRM IN WALLET' : 'SIGN MESSAGE'}
          </Button>
          <Button
            type="button"
            onClick={openAccountModal}
            className="m-0 h-14 w-full rounded-none bg-black font-mono text-lg font-normal uppercase tracking-wider text-foreground hover:bg-black"
            disabled={isRegistering}
          >
            Disconnect
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default RegisterDialog;
