import { useMutation } from '@tanstack/react-query';
import { useAccountStore } from '@/store/use-account-store';
import { privateApi } from '@/lib/api-clients/rest-client';

import { useUnifiedAccount } from '@/hooks';

type ListenKeyResponse = {
  listenKey: string;
};

const onSuccess = (address: string, res: ListenKeyResponse) => {
  const { getAuthParamsByAddress, setAuthParams, getAccurateTime } =
    useAccountStore.getState();

  const authParams = getAuthParamsByAddress(address);

  if (!authParams) {
    console.error('No auth params found');
    return;
  }

  setAuthParams(address, {
    ...authParams,
    listenKey: res.listenKey,
    listenKeyExpiry: getAccurateTime() + 1000 * 60 * 59, // 59 minutes, server by default is 1 hour
  });
};

export function useListenKey() {
  const { address } = useUnifiedAccount();

  return useMutation({
    mutationFn: async (): Promise<ListenKeyResponse> => {
      return privateApi.post('/account/listenKey', {});
    },
    onSuccess: (res) => onSuccess(address || '', res),
  });
}

export function useRefreshListenKey() {
  const { address } = useUnifiedAccount();

  return useMutation({
    mutationFn: async (): Promise<ListenKeyResponse> => {
      return privateApi.put('/account/listenKey', {});
    },
    onSuccess: (res) => onSuccess(address || '', res),
  });
}
