import { useQuery, queryOptions } from '@tanstack/react-query';
import { z } from 'zod';
import { QueryConfig } from '@/lib/api-clients/react-query';
import { AccountResponse } from '@/types/api';
import {
  COLLATERAL_DECIMALS,
  CUM_FUNDING_DECIMALS,
  useMarketStore,
} from '@/store/use-markets-store';
import { parseDecimalToBigInt } from '@/utils/value-format';
import {
  Account,
  AuthParams,
  Position,
  Positions,
  useAccountStore,
} from '@/store/use-account-store';
import { privateApi } from '@/lib/api-clients/rest-client';
import { useAccount } from 'wagmi';
import { useUnifiedAccount } from '@/hooks';

export const fetchAccountInputSchema = z.object({
  nonce: z.number(),
  signature: z.string(),
});

export type FetchAccountInput = z.infer<typeof fetchAccountInputSchema>;

export const fetchAccount = async (): Promise<AccountResponse> => {
  return privateApi
    .get<AccountResponse>('/account', {
      params: {},
    })
    .then((res: AccountResponse) => {
      const { collateral, positions, leverages } = res;
      const account: Account = {
        collateral: parseDecimalToBigInt(collateral, COLLATERAL_DECIMALS),
        positions: positions.reduce((acc: Positions, pos: any) => {
          const { marketSpec } = useMarketStore(pos.symbol).getState();
          acc[pos.symbol] = {
            isLong: pos.isLong,
            size: parseDecimalToBigInt(pos.size, marketSpec.sizeDecimals),
            cost:
              parseDecimalToBigInt(pos.entryPrice, marketSpec.priceDecimals) *
              parseDecimalToBigInt(pos.size, marketSpec.sizeDecimals),
            entryFunding: parseDecimalToBigInt(
              pos.entryFunding,
              CUM_FUNDING_DECIMALS,
            ),
            initMarginRatio: parseDecimalToBigInt(pos.initMarginRatio, 4n),
          };
          return acc;
        }, {} as Positions),
        leverages,
      };

      const { setAccount, setAccountLastUpdated } = useAccountStore.getState();
      setAccount(account);
      setAccountLastUpdated(res.time);

      positions.forEach((pos) => {
        const { marketData, marketSpec, setMarketData } = useMarketStore(
          pos.symbol,
        ).getState();
        setMarketData({
          ...marketData,
          indexPrice: parseDecimalToBigInt(
            pos.indexPrice,
            marketSpec.priceDecimals,
          ),
          markPrice: parseDecimalToBigInt(
            pos.markPrice,
            marketSpec.priceDecimals,
          ),
        });
      });
      return res;
    });
};

export const getAccountQueryOptions = (authParams: AuthParams | undefined) => {
  return queryOptions({
    queryKey: [authParams?.signingKey], // temp: invalidate cache some other way?
    queryFn: () => fetchAccount(),
  });
};

type UseFetchAccountOptions = {
  queryConfig?: QueryConfig<typeof fetchAccount>;
};

export const useFetchAccount = ({
  queryConfig,
  ...params
}: UseFetchAccountOptions = {}) => {
  const { address } = useUnifiedAccount();
  const { hasAuth, authParams } = useAccountStore((state) => ({
    hasAuth: state.hasAuth,
    authParams: state.getAuthParamsByAddress(address),
  }));

  return useQuery({
    enabled: hasAuth,
    ...getAccountQueryOptions(authParams),
    ...queryConfig,
  });
};
