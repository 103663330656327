import { privateApi } from '@/lib/api-clients/rest-client';
import { useAccountStore } from '@/store/use-account-store';
import { useQuery } from '@tanstack/react-query';

type AccountValue = {
  total: string;
  ts: string;
};

async function getAccountValueHistory({
  offset,
  limit,
}: {
  offset: number;
  limit: number;
}): Promise<AccountValue[]> {
  const timeTo = new Date();
  const timeFrom = new Date();
  timeFrom.setTime(timeFrom.getTime() - offset);

  return privateApi
    .get('/account/value/history', {
      params: {
        timeFrom,
        timeTo,
        limit,
      },
    })
    .then((res) => res.data)
    .catch(() => []);
}

const useAccountValueHistory = (period: { offset: number; limit: number }) => {
  const hasAuth = useAccountStore((state) => state.hasAuth);
  return useQuery({
    queryKey: ['/account/value/history', period],
    queryFn: ({ queryKey: [_k, period] }) =>
      getAccountValueHistory(period as { offset: number; limit: number }),
    enabled: hasAuth,
    refetchInterval: 60_000,
  });
};

export default useAccountValueHistory;
