import { useEffect, useState } from 'react';
import { useAccountStore } from '@/store/use-account-store';
import {
  clearPrivateApiHeaders,
  updatePrivateApiHeaders,
} from '@/lib/api-clients/rest-client';
import { useAccount } from 'wagmi';
import { useOrdersStore } from '@/store/use-orders-store';
import { useTransfersStore } from '@/store/use-transfers-store';
import { useExchangeInfo } from '@/features/markets/api/get-exchange-info';
import { ErrorCode, LPOrderType, OrderStatus } from '@/types/enums';
import { useListenKey, useRefreshListenKey } from '@/lib/api/listen-key';
import { toast } from 'sonner';
import { useQueryClient } from '@tanstack/react-query';
import { logConsole } from '@/lib/utils';
import { useWallet } from '@solana/wallet-adapter-react';
import { useUnifiedAccount } from '@/hooks';

export const ApiProvider = ({ children }: { children: React.ReactNode }) => {
  const MAX_RETRIES = 5;

  const { status } = useAccount();
  const { isConnected, address: userAddress } = useUnifiedAccount();
  const { connected } = useWallet();
  const [retryCount, setRetryCount] = useState(0);

  const {
    hasAuth,
    authParams,
    setHasAuth,
    getAccurateTime,
    clearAccountStore,
    setAddress,
  } = useAccountStore((state) => ({
    authParams: state.getAuthParamsByAddress(userAddress),
    setHasAuth: state.setHasAuth,
    setAddress: state.setAddress,
    clearAccountStoreForAddress: state.clearAccountStoreForAddress,
    hasAuth: state.hasAuth,
    clearAccountStore: state.clearAccountStore,
    getAccurateTime: state.getAccurateTime,
  }));

  useEffect(() => {
    setAddress(userAddress as string);
  }, [userAddress]);

  const currentTimestamp = getAccurateTime();

  const keyExpiry = authParams?.listenKeyExpiry ?? 0; // in case of null, subtract and comparision works weird.

  const queryClient = useQueryClient();

  const {
    mutateAsync: getListenKey,
    error: _listenKeyError,
    reset: resetListenKey,
  } = useListenKey(); // if this error object is not null and is unauthorized, set to false
  const listenKeyError = _listenKeyError as unknown as ErrorCode;

  const {
    mutateAsync: refreshListenKey,
    error: _refreshListenKeyError,
    isPending: isListenKeyPending,
    reset: resetRefreshListenKey,
  } = useRefreshListenKey();
  const refreshListenKeyError = _refreshListenKeyError as unknown as ErrorCode;

  useEffect(() => {
    if (
      hasAuth &&
      (listenKeyError === ErrorCode.UNAUTHORIZED ||
        refreshListenKeyError === ErrorCode.UNAUTHORIZED)
    ) {
      clearPrivateApiHeaders(['xrestservermm', 'X-API-KEY']);
      setHasAuth(false);
      resetListenKey();
      resetRefreshListenKey();
    }
  }, [hasAuth, listenKeyError, refreshListenKeyError]);

  useEffect(() => {
    if (
      hasAuth &&
      !isListenKeyPending &&
      listenKeyError !== ErrorCode.ACCOUNT_NOT_FOUND &&
      (!authParams?.listenKey ||
        authParams.listenKeyExpiry <= currentTimestamp) &&
      retryCount < MAX_RETRIES
    ) {
      const timeout = setTimeout(async () => {
        await getListenKey();

        setRetryCount((prevRetryCount) => prevRetryCount + 1);
      }, 2000); // Retry after 2 seconds

      return () => clearTimeout(timeout); // Cleanup timeout on unmount or dependency change
    }

    if (retryCount >= MAX_RETRIES) {
      toast.error('Unable to retrieve Listen Key after multiple attempts.');
    }
  }, [
    hasAuth,
    userAddress,
    isListenKeyPending,
    listenKeyError,
    authParams?.listenKey,
    authParams?.listenKeyExpiry,
    retryCount,
  ]);

  useEffect(() => {
    if (!hasAuth || listenKeyError === ErrorCode.ACCOUNT_NOT_FOUND) {
      setRetryCount(0); // Reset retries when not authorized or account not found
    }
  }, [hasAuth, listenKeyError]);

  useEffect(() => {
    let refreshTimeoutId: NodeJS.Timeout;

    if (authParams?.listenKey && keyExpiry > currentTimestamp) {
      refreshTimeoutId = setTimeout(() => {
        logConsole(false, 'Refresh Listen Key');
        refreshListenKey();
      }, keyExpiry - currentTimestamp);
    }

    return () => {
      clearTimeout(refreshTimeoutId);
    };
  }, [authParams?.listenKey, keyExpiry]);

  useEffect(() => {
    if (status === 'connecting' || status === 'reconnecting') return;

    if (status === 'disconnected' && !connected) {
      // Connected to wrong account / switched account / disconnected / auth expired
      // logConsole(false,
      //   'Connected to wrong account or switched account or disconnected, clearing auth from storage...',
      // );

      console.error('disconnected, clearing auth from storage');
      clearAccountStore();
      const { clearTransfersStore } = useTransfersStore.getState();
      const { clearOrdersStore } = useOrdersStore.getState();
      clearTransfersStore();
      clearOrdersStore();
      // useAccountStore.persist.clearStorage();
      clearPrivateApiHeaders(['xrestservermm', 'X-API-KEY']);
      setHasAuth(false);

      queryClient.removeQueries({
        queryKey: [
          'lpOrders',
          { orderType: LPOrderType.SCHEDULE_WITHDRAW, status: OrderStatus.NEW },
        ],
        exact: true,
      });
    }
  }, [status, userAddress, connected]);

  const { isPending, isError } = useExchangeInfo();
  if (isPending || isError) {
    return <> </>;
  }

  return <>{children}</>;
};
