import { useState, useRef, useEffect } from 'react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { cn } from '@/utils/cn';

import { truncateAddress } from '@/utils/format';
import { Connector } from 'wagmi';
import { Settings } from 'lucide-react';
import { getConnectorIcon } from '@/lib/utils';
import { useUnifiedAccount } from '@/hooks';

interface MenuOption {
  label: string;
  connector: Connector;
  action: () => void;
}

interface AccountMenuProps {
  children: React.ReactNode;
  options: MenuOption[];
  openAccountModal: () => void;
  openConnectModal: () => void;
}

export const AccountMenu: React.FC<AccountMenuProps> = ({
  children,
  options,
  openAccountModal,
  openConnectModal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { address } = useUnifiedAccount();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative grid" ref={menuRef}>
      <button
        className={cn(
          'flex items-center justify-center gap-2 px-5',
          'hover:bg-vestgrey-700 cursor-pointer',
        )}
        onClick={() => setIsOpen(!isOpen)}
        aria-haspopup="menu"
        aria-expanded={isOpen}
      >
        {children}
        <ChevronDownIcon
          className={cn('text-vestgrey-200 transition-transform duration-300', {
            'rotate-180': isOpen,
          })}
        />
      </button>
      {isOpen && (
        <div
          className="border-vestgrey-700 absolute right-0 top-full z-50 w-full rounded-md bg-vestgrey-900 shadow-lg"
          role="menu"
        >
          {options.map((option, index) => (
            <div
              key={index}
              className="flex cursor-pointer items-center justify-between border-t border-border px-6 py-4 text-sm font-normal text-foreground hover:bg-vestgrey-800"
              onClick={() => {
                if (address == option.label) openAccountModal();
                else option.action();
                setIsOpen(false);
              }}
              role="menuitem"
              tabIndex={index}
            >
              <div className="flex items-center gap-2">
                <img
                  src={
                    option.connector.icon
                      ? option.connector.icon
                      : getConnectorIcon(option.connector)
                  }
                  className="h-6 w-6 rounded-md"
                  alt="icon"
                />
                {truncateAddress(option.label)}
              </div>
              {address == option.label && <Settings size={16} />}
            </div>
          ))}
          {/* <div
            className="cursor-pointer border-t border-border px-6 py-4 text-center text-sm font-normal hover:bg-vestgrey-800"
            onClick={() => {
              openConnectModal();
            }}
            role="menuitem"
          >
            ADD WALLET
          </div> */}
          <div
            className="cursor-pointer border-t border-border px-6 py-4 text-center text-sm font-normal hover:bg-vestgrey-800"
            onClick={() => {
              openAccountModal();
            }}
            role="menuitem"
          >
            View Profile
          </div>
        </div>
      )}
    </div>
  );
};
