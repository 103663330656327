import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import {
  Button,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@/components/ui';
import LPStats from '@/features/lp/components/lp-stats';
import PendingWithdrawalsTable from '@/features/lp/components/pending-withdrawals-table';
import { LPTransferDialog } from '@/features/lp/components/lp-transfer-dialog';
import PerformanceChart from '@/features/lp/components/performance-chart';
import {
  ExchangeInfo,
  useExchangeInfo,
} from '@/features/markets/api/get-exchange-info';
import { cn } from '@/lib/utils';
import { useAccountStore } from '@/store/use-account-store';
import { LPOrderType } from '@/types';
import { formatNumber } from '@/utils/value-format';
import useLpPerformance, { PERIODS } from '@/features/lp/api/get-performance';
import { useMemo } from 'react';
import { Info } from 'lucide-react';
import { Link } from 'react-router-dom';
import HistoricalTransfersTable from '@/features/lp/components/historical-transfers-table';
import useOpportunities from '@/features/lp/api/get-opportunities';

export default function LPPage() {
  const { hasAuth } = useAccountStore();
  const { data } = useExchangeInfo();
  const exchangeInfo = data as ExchangeInfo;
  const { data: lpPerformance } = useLpPerformance(PERIODS[0]);

  const { data: opportunities } = useOpportunities();
  const igniteAPR = opportunities
    ? Math.round(opportunities.reduce((prev, curr) => prev + curr.apr, 0))
    : 0;

  const totalApy = useMemo(() => {
    if (!Array.isArray(lpPerformance) || lpPerformance.length === 0) return 0;
    const numberOfDays = PERIODS[0].offset / (24 * 60 * 60 * 1000);
    const sortedLpPerformance = lpPerformance.sort(
      (a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime(),
    );
    const performanceApy =
      (parseFloat(
        sortedLpPerformance[sortedLpPerformance.length - 1].pricePerShare,
      ) /
        parseFloat(sortedLpPerformance[0].pricePerShare) -
        1) *
      (365 / numberOfDays) *
      100;
    return Math.round(performanceApy);
  }, [lpPerformance]);

  return (
    <div className="flex h-full flex-col">
      <div className="grid grid-cols-5 items-center border-b border-border bg-background py-10">
        <div className="space-y-10 pl-8">
          <h1 className="text-2xl font-semibold sm:text-3xl lg:text-4xl">
            Liquidity Providing
          </h1>
          <dl className="grid grid-cols-3">
            <div className="flex flex-col gap-2">
              <dt className="font-mono text-sm uppercase tracking-wider text-vestgrey-600">
                Expected APY
              </dt>
              <dd className="text-font text-xl font-bold">
                <div className="flex items-center gap-2">
                  <span>{totalApy + igniteAPR}%</span>
                  <TooltipProvider>
                    <Tooltip delayDuration={0}>
                      <TooltipTrigger>
                        <Info size={14} className="mb-0.5" />
                      </TooltipTrigger>
                      <TooltipContent side="right">
                        <ul className="list-disc pl-4 text-left text-base">
                          <li>Base APY - {totalApy}%</li>
                          <li>
                            <Link
                              to="https://app.zksyncignite.xyz/opportunities/zksync-era/VEST/0x7ccF5BbeC69c790D27dA3b5398B9e0d6D6EeC9F3"
                              target="_blank"
                              rel="noreferrer"
                              className="underline"
                            >
                              Ignite APY - {igniteAPR}%
                            </Link>
                          </li>
                        </ul>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </dd>
            </div>
            <div className="flex flex-col gap-2">
              <dt className="font-mono text-sm uppercase tracking-wider text-vestgrey-600">
                TVL
              </dt>
              <dd className={cn('text-font text-xl font-bold')}>
                {formatNumber(exchangeInfo.exchange.lp, {
                  digits: 2,
                  compact: true,
                  round: 'ceil',
                })}
              </dd>
            </div>
            {/* <div className="flex flex-col gap-2">
              <dt className="font-mono text-sm uppercase tracking-wider text-vestgrey-600">
                Max drawdown
              </dt>
              <dd className={cn('text-font text-xl font-bold')}>-</dd>
            </div> */}
          </dl>
        </div>
        <div className="col-span-3 px-8">
          <p className="max-w-7xl text-vestgrey-100">
            This vault provides liquidity by acting as the counterparty to
            trades. While temporary drawdowns can occur, it accrues fees and
            charges risk-based premia and funding to support long-term
            performance. The lock up period is 8 hours. Learn more{' '}
            <a
              href="https://docs.vest.exchange/overview/liquidity-providers"
              className="font-medium underline"
              target="_blank"
            >
              here
            </a>
            .
          </p>
        </div>
        <div className="px-8">
          <LPTransferDialog orderType={LPOrderType.DEPOSIT}>
            <Button
              disabled={!hasAuth}
              size="lg"
              className="h-auto rounded-none px-12 py-6 font-mono uppercase tracking-wider"
            >
              Provide Liquidity
            </Button>
          </LPTransferDialog>
        </div>
      </div>
      <div className="grid grid-cols-4 grid-rows-[max-content_1fr]">
        <LPStats />
        <PerformanceChart />
      </div>
      <Tabs className="flex w-full flex-1 flex-col" defaultValue="pending">
        <div className="flex">
          <TabsList>
            <TabsTrigger className="h-16" value="pending">
              PENDING WITHDRAWS
            </TabsTrigger>
            <TabsTrigger className="h-16" value="historical">
              HISTORICAL TRANSFERS
            </TabsTrigger>
          </TabsList>
          <div className="flex-1 border-y border-border bg-background font-mono"></div>
        </div>
        <TabsContent className="relative flex-1" value="pending">
          <div className="absolute inset-0 h-full w-full">
            <PendingWithdrawalsTable />
          </div>
        </TabsContent>
        <TabsContent className="relative flex-1" value="historical">
          <div className="absolute inset-0 h-full w-full">
            <HistoricalTransfersTable />
          </div>
        </TabsContent>
      </Tabs>
      {/* <div className="relative flex-1">
        <div className="absolute inset-0 h-full w-full">
          <PendingWithdrawalsTable />
        </div>
      </div> */}
    </div>
  );
}
