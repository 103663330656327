import { useConnection, useWallet } from '@solana/wallet-adapter-react';

import { useConnections } from 'wagmi';
import { useMemo } from 'react';

export const useUnifiedConnections = () => {
  const connections = useConnections();
  const { wallet } = useWallet();

  const unifiedConnections = useMemo(() => {
    return [...connections, wallet?.adapter];
  }, [connections, wallet]);

  return unifiedConnections;
};
