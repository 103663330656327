import { useMutation } from '@tanstack/react-query';
import { privateApi } from '@/lib/api-clients/rest-client';

export function useTransferDepositSolana() {
  return useMutation({
    mutationFn: async (transactionHash: string): Promise<Response> => {
      return privateApi.post('/transfer/deposit/solana', {
        transactionHash,
      });
    },
    onSuccess: (res) => {},
  });
}
