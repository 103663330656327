import { SOLANA_CHAIN_ID, solNetwork } from '@/lib/wagmi/config';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { useMemo } from 'react';
import { useAccount } from 'wagmi';

export const useUnifiedAccount = () => {
  const {
    isConnected: _isConnected,
    chainId,
    address,
    connector,
    chain,
  } = useAccount();
  const { connected, publicKey, wallet } = useWallet();
  const { connection } = useConnection();

  const getNetworkName = () => {
    const endpoint = connection.rpcEndpoint;

    if (endpoint.includes('devnet')) {
      return 'Solana Devnet';
    } else if (endpoint.includes('testnet')) {
      return 'Solana Testnet';
    } else if (endpoint.includes('mainnet')) {
      return 'Solana Mainnet Beta';
    } else {
      return 'Unknown';
    }
  };

  // console.error(connector?.name, address, publicKey?.toBase58());

  const isConnected = _isConnected || connected;

  const info = useMemo(() => {
    if (isConnected) {
      const networkName = getNetworkName();
      return connected
        ? {
            networkType: 1,
            address: publicKey?.toBase58(),
            chain: { name: networkName, id: SOLANA_CHAIN_ID },
            chainId: solNetwork,
            connector: wallet?.adapter,
          }
        : {
            networkType: 0,
            address: address as string,
            chainId,
            chain,
            connector,
          };
    }
    return {
      networkType: 0,
      address: '',
      chainId: '',
      connector: null,
    };
  }, [
    isConnected,
    connected,
    publicKey,
    wallet,
    connector,
    address,
    chainId,
    connection,
  ]);

  return { isConnected, ...info };
};
