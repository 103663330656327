import { FC, useEffect, useState } from 'react';
import { useAccount, useDisconnect } from 'wagmi';

import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/components/ui';
import { truncateAddress } from '@/utils/format';
import { Check, Copy } from 'lucide-react';
import { getConnectorIcon } from '@/lib/utils';
import { useTransfersStore } from '@/store/use-transfers-store';
import { useOrdersStore } from '@/store/use-orders-store';
import { useAccountStore } from '@/store/use-account-store';
import { useUnifiedAccount, useUnifiedConnect } from '@/hooks';

interface AccountModalProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  displayTrigger?: boolean;
}

export const AccountModal: FC<AccountModalProps> = ({
  isOpen: externalIsOpen,
  onOpenChange = () => {},
  displayTrigger = true,
}) => {
  const { address, connector } = useUnifiedAccount();
  const { disconnect } = useUnifiedConnect();
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const [copied, setCopied] = useState<boolean>(false);
  const isControlled = externalIsOpen !== undefined;
  const isOpen = isControlled ? externalIsOpen : internalIsOpen;
  const { clearAccountStoreForAddress } = useAccountStore((state) => ({
    clearAccountStoreForAddress: state.clearAccountStoreForAddress,
  }));

  useEffect(() => {
    if (isControlled) {
      setInternalIsOpen(externalIsOpen);
    }
  }, [externalIsOpen, isControlled]);

  const handleOpenChange = (newIsOpen: boolean) => {
    if (!isControlled) {
      setInternalIsOpen(newIsOpen);
    }
    onOpenChange?.(newIsOpen);
  };

  const handleDisconnect = async () => {
    await disconnect();

    const { clearTransfersStore } = useTransfersStore.getState();
    const { clearOrdersStore } = useOrdersStore.getState();
    clearAccountStoreForAddress(address || '');
    clearTransfersStore();
    clearOrdersStore();
    console.error(`Disconnecting ${address}`);
    handleOpenChange(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogContent className="gap-0 border border-border bg-background p-0 sm:max-w-sm sm:rounded-none">
        <DialogHeader className="px-5 pt-6">
          <DialogTitle className="text-xl text-foreground">Account</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center justify-center gap-3 py-6">
          {connector && (
            <img
              src={
                connector.icon ? connector.icon : getConnectorIcon(connector)
              }
              className="h-12 w-12 rounded-md"
              alt="icon"
            />
          )}
          <div className="flex items-center gap-1">
            {truncateAddress(address || '', 6)}
            <Button
              size="icon"
              onClick={() => {
                navigator.clipboard.writeText(address || '');
                setCopied(true);
              }}
              className="h-10 w-10 rounded-none bg-background font-mono uppercase text-foreground hover:bg-background hover:text-foreground"
            >
              {copied ? <Check size={16} /> : <Copy size={16} />}
            </Button>
          </div>
        </div>
        <DialogFooter className="sm:flex-col sm:gap-0 sm:space-x-0">
          <Button
            type="button"
            className="h-14 w-full rounded-none font-mono text-lg font-normal tracking-wider"
            onClick={handleDisconnect}
          >
            DISCONNECT
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
