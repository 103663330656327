import useLpPerformance, { PERIODS } from '../api/get-performance';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createChart, ISeriesApi, UTCTimestamp } from 'lightweight-charts';
import { cn } from '@/lib/utils';
import triangleLoader from '@/assets/branding/triangles-loader.gif';
import useOpportunities from '../api/get-opportunities';

export default function PerformanceChart() {
  const [period, setPeriod] = useState(PERIODS[0]);
  const { data, isLoading } = useLpPerformance(period);

  const chartContainerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<ReturnType<typeof createChart>>();
  const seriesRef = useRef<ISeriesApi<'Line'>>();

  const { data: opportunities } = useOpportunities();
  const igniteAPR = opportunities
    ? Math.round(opportunities.reduce((prev, curr) => prev + curr.apr, 0))
    : 0;

  const formattedData = useMemo(() => {
    if (!Array.isArray(data)) return [];
    const formattedData = [...data];
    formattedData.sort(
      (a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime(),
    );
    const numberOfDays = period.offset / (24 * 60 * 60 * 1000);
    return formattedData.map(({ pricePerShare, ts }, _i, arr) => ({
      value:
        (parseFloat(pricePerShare) / parseFloat(arr[0].pricePerShare) - 1) *
          (365 / numberOfDays) *
          100 +
        igniteAPR,
      time: (new Date(ts).getTime() / 1000) as UTCTimestamp,
    }));
  }, [data, period.offset]);

  useEffect(() => {
    if (isLoading || !chartContainerRef.current) return;
    chartRef.current = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        background: { color: '#0f0f0f' }, // Corrected property name and provided the correct type
        textColor: '#F5F5F5',
      },
      grid: {
        vertLines: {
          color: '#222127', // Match TradingView chart grid color
        },
        horzLines: {
          color: '#222127', // Match TradingView chart grid color
        },
      },
      timeScale: {
        timeVisible: true,
        secondsVisible: true,
        fixLeftEdge: true,
        fixRightEdge: true,
      },
      autoSize: true,
    });

    const isProfit =
      formattedData.length > 0 &&
      formattedData[formattedData.length - 1].value > formattedData[0].value;
    const lineColor = isProfit ? '#4BC2A3' : '#FF4B4B'; // Green for profit, red for loss

    seriesRef.current = chartRef.current.addLineSeries({
      color: lineColor,
      lineWidth: 2,
    });

    seriesRef.current.setData(formattedData);

    return () => chartRef.current?.remove();
  }, [isLoading]);

  useEffect(() => {
    if (isLoading || !seriesRef.current) return;

    const isProfit =
      formattedData.length > 0 &&
      formattedData[formattedData.length - 1].value > formattedData[0].value;
    const lineColor = isProfit ? '#4BC2A3' : '#FF4B4B'; // Green for profit, red for loss

    seriesRef.current.applyOptions({ color: lineColor });
    seriesRef.current.setData(formattedData);
  }, [isLoading, data, formattedData]);

  return (
    <div className="col-span-3 flex flex-col">
      <div className="flex h-16 items-center border-b border-border px-8">
        <h3 className="text-font text-lg font-semibold sm:text-xl">
          Historical APY
        </h3>
        <div className="flex h-full flex-1 justify-end">
          {PERIODS.map((p) => (
            <button
              className={cn(
                'h-full w-20 font-mono',
                period.offset === p.offset
                  ? 'font-semibold text-foreground'
                  : 'text-vestgrey-600',
              )}
              onClick={() => setPeriod(p)}
              key={p.offset}
            >
              {p.label}
            </button>
          ))}
        </div>
      </div>
      {isLoading ? (
        <div className="flex flex-1 flex-col items-center justify-center">
          <img src={triangleLoader} alt="" className="w-16" />
        </div>
      ) : (
        <div className="flex-1">
          <div className="h-full w-full" ref={chartContainerRef} />
        </div>
      )}
    </div>
  );
}
