import { Link } from 'react-router-dom';
import triangleLoader from '@/assets/branding/triangles-loader.gif';
import useLpPerformance, { PERIODS } from '@/features/lp/api/get-performance';
import { useMemo } from 'react';
import useOpportunities from '@/features/lp/api/get-opportunities';

export default function AnnouncementBanner() {
  const { data: lpPerformance, isLoading } = useLpPerformance(PERIODS[0]);

  const { data: opportunities } = useOpportunities();
  const igniteAPR = opportunities
    ? Math.round(opportunities.reduce((prev, curr) => prev + curr.apr, 0))
    : 0;

  const totalApy = useMemo(() => {
    if (!Array.isArray(lpPerformance) || lpPerformance.length === 0) return 0;
    const numberOfDays = PERIODS[0].offset / (24 * 60 * 60 * 1000);
    const sortedLpPerformance = lpPerformance.sort(
      (a, b) => new Date(a.ts).getTime() - new Date(b.ts).getTime(),
    );
    const performanceApy =
      (parseFloat(
        sortedLpPerformance[sortedLpPerformance.length - 1].pricePerShare,
      ) /
        parseFloat(sortedLpPerformance[0].pricePerShare) -
        1) *
      (365 / numberOfDays) *
      100;
    return Math.round(performanceApy);
  }, [lpPerformance]);

  return (
    <div className="flex items-center border-b border-border px-8 py-4">
      <p className="text-lg tracking-wider">
        <strong className="mr-2 inline-block">🎉 Rewards are now live!</strong>{' '}
        <span>
          Earn up to{' '}
          {isLoading ? (
            <img className="inline-block w-8" src={triangleLoader} alt="-" />
          ) : lpPerformance ? (
            <span className="text-primary">{totalApy + igniteAPR}%</span>
          ) : (
            '-'
          )}{' '}
          APY by{' '}
          <Link className="inline underline" to="/liquidity-providing">
            providing liquidity
          </Link>
          .
        </span>
      </p>
    </div>
  );
}
